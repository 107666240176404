@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
#healthBox{
    background-color: rgba(255, 255, 255, 0.5);;
    position: absolute;
    z-index: 2;



}
/*
#imageband{
    display: flex;

    position: relative;
    z-index: 1;

}

 */
/*
img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
 */

h1{


    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;

    /* or 47% */

    text-transform: capitalize;

    color: #5F0E78;

}

#h1Subtext{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;

    /* or 113% */


            color: #26770E;

}

.image-stack {
    display: grid;
    position: relative;
grid-template-columns: repeat(12, 1fr);
}

.image-stack__item--bottom {
    grid-column: 4 / -1;
    grid-row: 1;
}

.image-stack__item--top {
    grid-row: 1;
    grid-column: 1 / span 8;
    padding-top: 20%;
z-index: 1;
}

#siteContainer{
    margin: 0px;
    padding: 0px;
    width: 100%;
    max-width: 100%;
    font-family: Source Sans Pro !important;
    font-style: normal  !important;
    font-weight: normal  !important;
}

button{
    width: 20vw;
    height: 3.75vw;

    color: #FFFFFF;
    background: #5F0E78;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px #FFFFFF;
     border-radius: 25px;
}



col{
    padding-right: 0px !important;
}