@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
#unselectedButton{

    height: 3vw;
    width: 9vw;
    color: white;
    background-color: #5F0E78;
    font-size: 1vw;

    border-radius: 90%!important;

    z-index: 100;
    position: relative;
}

#selectedButton{

    height: 3vw;
    width: 9vw;
    color: #5F0E78;
    background-color: white;
    border-color: #5F0E78;
    font-size: 1vw;

    border-radius: 90%!important;

    z-index: 100;
    position: relative;
}


#selectedButtonMobile{

    height: 10vw;
    width: 30vw;
    color: #5F0E78;
    background-color: white;
    border-color: #5F0E78;
    font-size: 4vw;

    border-radius: 90%!important;

    z-index: 100;
    position: relative;
}

#unselectedButtonMobile{

    height: 10vw;
    width: 30vw;
    color: white;
    background-color: #5F0E78;
    font-size: 4vw;

    border-radius: 90%!important;

    z-index: 100;
    position: relative;
}

#selectHeading{
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;


    color: #5F0E78;
}
.dropdown-toggle::before {
    content: "▼";
    color:#5F0E78;
}
.dropdown-toggle{
    background-color:white;
    border: #FFFFFF;
    color: #5F0E78;
}
.dropdown-toggle::after {
    display: none;

}

div.btn-group.show div.dropdown-menu.show {
    background-color: #4b4b4b;
}

div.btn-group.show div.dropdown-menu.show button.dropdown-item {
    color: white;
}

div.btn-group.show div.dropdown-menu.show div.dropdown-divider {
    border-top: 1px solid white;
}

div.btn-group.show div.dropdown-menu.show button.dropdown-item:hover,
div.btn-group.show div.dropdown-menu.show button.dropdown-item:focus {
    background-color: #1e1e1e;
    color: #f0f0f0;
}

 .btn-success:hover {
     color: #5F0E78;
     background-color: white;
     border-color: white;
 }

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
}


.btn-primary.focus, .btn-primary:focus, .btn-primary:hover,.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    color: #fff;
    background-color: #5F0E78;
    border-color: #5F0E78;

}