input{
    float: right;
}

.addButton{
    float:right;
    width: auto;
    height: auto;
}

label
{
    display: block !important;
}

label span
{
    display: inline-block;
    text-align: right;
}

select span
{
    display: inline-block;
    text-align: right;
}
.removeButton{
    background-color: #770E49;
    height: 2em;
    width:5em;
    margin-bottom: 10px;
}

.listInput{
    float: left!important;
}