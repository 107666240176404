#root{
    height: 100%;
}

body{
    height: 100%;
}

html{
    height:100%
}

.row{

    margin-right: 0px!important;
}