h2, h3{
    color: rgb(95, 14, 120);
}

h3{
    padding-left: 3em;
}

.indent{
    padding-left: 6em;
}

span{
    font-size: 1em;
    color: rgb(95, 14, 120);
}